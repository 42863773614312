import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Hair from '../sections/pricelist/hairprice.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Skin from "../sections/pricelist/skinprice.js";
import Exosomes from "../sections/pricelist/exoprice.js";
import PoPrice from "../sections/pricelist/poprice.js";
import Hero from "../sections/pricelist/pricelisthero.js";
import Nose from "../sections/pricelist/noseprice.js";
import Fat from "../sections/pricelist/fatprice.js";
import Peels from "../sections/pricelist/peelprice.js";
import Meso from "../sections/pricelist/mesoprice.js";
import Micro from "../sections/pricelist/microprice.js";
import Hydra from "../sections/pricelist/hydraprice.js";
import Ad from "../sections/pricelist/advancedprice.js";
import Face from "../sections/pricelist/faceprice.js";
import Anti from "../sections/pricelist/anti.js";
import Removal from "../sections/pricelist/removalprices.js";
import Con from "../sections/pricelist/conprices.js"
import SkinCare from "../sections/pricelist/skincareprice.js";
import Accordion from "../shared/accordion.js";
import CardComponent from '../shared/cardloop.js';
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/price-hero.jpg";

const accordionItems = [
  {
    title: 'Non Surgical Nose Job',
    content: <Nose />,
  },
  {
    title: 'Hair Loss',
    content: <Hair />,
  },

  {
    title: 'Consultation',
    content: <Con />,
  },
  {
    title: 'Exosomes',
    content: <Exosomes />,
  },
  {
    title: ' Polynucleotides',
    content: <PoPrice />,
  },
 
  {
    title: 'Skin Booster',
    content: <Skin />,
  },
  {
    title: 'Anti-Wrinkle / Other',
    content: <Anti />,
  },
  {
    title: 'Dermal Fillers',
    content: <Face />,
  },
  {
    title: 'Fat Dissolving',
    content: <Fat />,
  },
  {
    title: 'Peels',
    content: <Peels />,
  },

  {
    title: 'PRP',
    content: <SkinCare />,
  },

  {
    title: 'Skinpen® Microneedling',
    content: <Micro />,
  },



  {
    title: 'Hydrafacial',
    content: <Hydra />,
  },
  {
    title: 'Advanced Facials',
    content: <Ad />,
  },

  {
    title: 'Mesotherapy',
    content: <Meso />,
  },

  {
    title: 'Laser Hair Removal',
    content: <Removal />,
  },

 
];

const TreatmentsPriceListPage = () => ( 
  <>
 <Head title="Non Surgical Treatment Price List in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Treatment </span> <span style="color: #00aec7;">Price List</span>'
    secondText="All treatments begin
    with a consultation"
    videoAlt="Dermamina Treatment Price List"
    imgVideo={imgVideo} // Specify your image URL here
  />
      <Accordion items={accordionItems} />
      <Saveface />
      <CardComponent />
     
     </PageWrapper>
 
  </>
)
export default TreatmentsPriceListPage
